<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import generalForm from "./detail/general-form.vue";
import workingHeightForm from "./form/working-height-form.vue";
import excavationForm from "./form/excavation-form.vue";
import confinedSpaceForm from "./form/confined-space-form.vue";
import energizedForm from "./form/energized-form.vue";
import hotForm from "./form/hot-form.vue";
import moment from "moment";
import $ from "jquery";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Work Permit Request",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        generalForm,
        workingHeightForm,
        excavationForm,
        confinedSpaceForm,
        energizedForm,
        hotForm,
    },
    data() {
        return {
            title: "Work Permit Request",
            searchDataTable: "",
            filter_work_type: [],
            work_type_selected: [],
            permitId: this.$route.params.permitId,
            isLoading: true,
            work_type_data: "",
            is_tracking: this.$route.params.is_tracking ?? false,
            work_permit_no: "",
            edit_field: true,
            data_detail: null,
            hak_akses: false,
            status_sekarang: null,
            status_selanjutnya: null,
            status_submit: null,
            catatan_verifikasi: null,
            modalTolakSubmit: false,
            modal_timeline: false,
            timeline: null,
            matrix_timeline: null,
            evidence_raw: [],
            modal_evidence: false,
            dokumen_proses: [],
            modal_verifikator: false,
            modal_verifikator_closing: false,
            optionVerifikator: [],
            verifikatorSelected: null,
            // general
            work_location: "",
            instalasi: "",
            equipment: "",
            work_order_no: "",
            process_owner: "",
            performing_task: "",
            jumlah_pekerja: "",
            berlaku_sejak: "",
            berlaku_hingga: "",
            dimulai_pukul: "",
            dimulai_hingga: "",
            keterangan: "",
            vendor: "",
            uraian: "",
            kemungkinan_bahaya: "",
            bahaya_rendah: "",
            bahaya_tinggi: "",
            tindakan_pencegahan: "",
            apd_selected: [],
            qr_code: "",
            work_permit_closing: [],
            work_permit_closing_detail: [],
            hak_akses_closing: false,
            status_sekarang_closing: null,
            status_selanjutnya_closing: null,
            modalTolakSubmitClosing: false,
            user_next_process: [
                {
                    user: null,
                },
            ],
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            var config_type = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config_type)
                .then((response) => {
                    this.filter_work_type = response.data.data[0].data;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    console.log(this.axiosCatchError);
                });

            // Get Data Detail
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/detail", {
                    params: {
                        id: this.permitId,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    console.log(response_data_fix);
                    if (response_data.meta.code == 200) {
                        var data_edit = response_data_fix.work_permit;
                        this.data_detail = data_edit;
                        this.hak_akses = response_data_fix.hak_akses;
                        this.status_sekarang = response_data_fix.status_proses;
                        this.status_selanjutnya = response_data_fix.status_selanjutnya;
                        this.timeline = response_data_fix?.work_permit?.work_permit_request_approval;
                        this.matrix_timeline = response_data_fix?.work_permit?.work_permit_request_matrix;
                        this.dokumen_proses = response_data_fix?.work_permit?.work_permit_request_dokumen;
                        this.evidence_raw = response_data_fix?.work_permit?.evidence;

                        if (data_edit.work_permit_closing) {
                            this.work_permit_closing = data_edit.work_permit_closing;
                            var closing_detail = JSON.parse(data_edit.work_permit_closing.detail_closing);
                            this.work_permit_closing_detail = closing_detail;
                            this.hak_akses_closing = response_data_fix.hak_akses_closing;
                            this.status_sekarang_closing = response_data_fix.status_proses_closing;
                            this.status_selanjutnya_closing = response_data_fix.status_selanjutnya_closing;
                        }
                        if (data_edit.work_permit_closing && response_data_fix?.status_selanjutnya_closing?.mspk_status_kategori != "Draft") {
                            this.getUser(response_data_fix?.status_selanjutnya_closing?.mspk_role_id, response_data_fix?.status_selanjutnya_closing?.mspk_id);
                        } else {
                            this.getUser(response_data_fix?.status_selanjutnya?.mspk_role_id);
                        }
                        this.work_type_data = data_edit.work_permit_type;
                        this.work_type_selected = data_edit.type;
                        this.qr_code = process.env.VUE_APP_BACKEND_URL + data_edit.qr_path;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                });
        },
        getUser(role_id, status_id) {
            let self = this;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-user", {
                    params: {
                        role_id: role_id,
                        status_id: status_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    console.log(response, "response data type");
                    self.optionVerifikator = response.data.data.referensi;
                    console.log(self.optionVerifikator, "response data type");
                    Swal.close();
                })
                .catch((e) => {
                    Swal.close();
                    this.axiosCatchError = e.response.data.data;
                    console.log(this.axiosCatchError);
                });
        },
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("MMMM Do YYYY, h:mm:ss a");
        },
        getNamaStatus(key) {
            var timeline_use = this.timeline[key];
            var approval_start = JSON.parse(timeline_use.approval_start);
            if (timeline_use.status_permit_kategori == "Pengajuan" || timeline_use.status_permit_kategori == "Draft") {
                approval_start = JSON.parse(timeline_use.approval_end);
            }
            return approval_start?.mspk_msp_nama;
        },
        checkProsesMatrix(timeline_use) {
            var processNow = JSON.parse(timeline_use.approval_end);
            var id_now = timeline_use?.approval_end_id;
            var asignTo = null;
            if (processNow?.is_multi_user == "t") {
                var getMatrix = [];
                if (timeline_use.work_permit_request_matrix.length >= 1) {
                    timeline_use.work_permit_request_matrix.forEach((val) => {
                        if (val.konfig_status_id === id_now) {
                            getMatrix.push(val.user.name);
                        }
                    });
                } else {
                    this.matrix_timeline.forEach((val) => {
                        if (val.konfig_status_id === id_now && val.asign_from_timeline_id == null) {
                            getMatrix.push(val.user.name);
                        }
                    });
                }

                asignTo = getMatrix;
            }

            return asignTo;
        },
        checkpdf(id) {
            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "generate/work-permit?id=" + id, "_blank");
        },
        opentimeline() {
            this.modal_timeline = true;
        },
        submitProcess(status) {
            let self = this;
            self.status_submit = status;
            if (self.status_sekarang?.is_approval) {
                self.modalTolakSubmit = true;
            } else if (self.status_sekarang?.is_evidence) {
                self.modal_evidence = true;
            } else {
                self.prosesVerifikasi();
            }
        },
        prosesVerifikasi() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            let required_form = new Promise(function (resolve, reject) {
                // cek required
                if (self.status_sekarang?.is_approval && (self.catatan_verifikasi == null || self.catatan_verifikasi == "")) {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan Remark Terisi",
                    });
                    reject("stop");
                }

                if (!self.user_next_process.length == 0 && !self?.user_next_process[0]?.user && self.status_selanjutnya?.is_multi_user == "t" && self.status_submit == "terima") {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan " + self.status_selanjutnya?.mspk_role_nama + " Terisi",
                    });
                    reject("stop");
                }
                if (self.status_sekarang?.is_evidence && self.evidence_raw.length == 0) {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan Evidence Terisi",
                    });
                    reject("stop");
                }
                if (self.status_sekarang?.is_evidence && self.evidence_raw.length > 0) {
                    for (let i = 0; i < self.evidence_raw.length; i++) {
                        if (self.evidence_raw[i].nama_file == null || self.evidence_raw[i].nama_file == "" || self.evidence_raw[i].path == null || self.evidence_raw[i].path == "") {
                            Swal.fire({
                                icon: "error",
                                title: "Pastikan Nama File Evidence Terisi",
                            });
                            reject("stop");
                        }
                    }
                }
                resolve("next");
            });
            required_form.then(function (response) {
                if (response == "stop") {
                    return false;
                }

                axios
                    .post(
                        process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/ubah-status",
                        {
                            status_submit: self.status_submit,
                            catatan_verifikasi: self.catatan_verifikasi,
                            id: self.permitId,
                            evidence_raw: self.evidence_raw,
                            user_next_process: self.user_next_process,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer " + localStorage.access_token,
                            },
                        }
                    )
                    .then(function (response) {
                        var response_data = response.data;
                        var response_data_fix = response_data.data;
                        console.log(response_data_fix);
                        if (response_data.meta.code == 200) {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Halaman segera diperbarui",
                                timer: 5000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                console.log(result);
                                /* Read more about handling dismissals below */
                                // if (result.dismiss === Swal.DismissReason.timer) {
                                Swal.close();
                                window.location.reload();
                                // }
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data_fix.data.message,
                            });
                        }
                    })
                    .catch((e) => {
                        self.axiosCatchError = e.response;
                        console.log(e);
                    });
            });
        },
        addEvidence() {
            this.evidence_raw.push({
                nama_file: null,
                path: null,
            });
        },
        removeEvidence(key_deleted) {
            this.evidence_raw.splice(key_deleted, 1);
        },

        uploadEvidence(keys) {
            let self = this;
            if ($("#files-doc-" + keys)[0].files[0]) {
                if ($("#files-doc-" + keys)[0].files[0].size < 2242880) {
                    $("#duloading-" + keys).html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var attachment_document_in = self.evidence_raw[keys];
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#files-doc-" + keys)[0].files[0]);
                    data.append("id_permit", self.permitId);
                    data.append("evidence", JSON.stringify(attachment_document_in));
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/upload-evidence",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config)
                        .then(function (response) {
                            attachment_document_in.path = response.data.data.url;
                            urlres += response.data.data.url;
                            $("#duloading-" + keys).html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> See Document</span></a>');
                            // self.evidence_raw[keys] = attachment_document_in;
                        })
                        .catch((e) => {
                            if (e?.response?.data?.data?.error) {
                                $("#duloading-" + keys).html('<span class="badge bg-danger p-1"><i class="fa fa-times"></i> ' + e?.response?.data?.data?.error + " </span>");
                            }
                            console.log(e.response);
                        });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
        getFile(files) {
            var win = window.open(process.env.VUE_APP_BACKEND_URL + files, "_blank");
            if (win) {
                //Browser has allowed it to be opened
                win.focus();
            } else {
                //Browser has blocked it
                alert("Please allow popups for this website");
            }
        },
        submitProcessClosing(status) {
            let self = this;
            self.status_submit = status;
            if (self.status_sekarang_closing?.is_approval) {
                self.modalTolakSubmitClosing = true;
            } else {
                self.prosesVerifikasiClosing();
            }
        },
        prosesVerifikasiClosing() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            let required_form = new Promise(function (resolve, reject) {
                // cek required
                if (self.status_sekarang_closing?.is_approval && (self.catatan_verifikasi == null || self.catatan_verifikasi == "")) {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan Remark Terisi",
                    });
                    reject("stop");
                }
                if (!self.user_next_process.length == 0 && !self?.user_next_process[0]?.user && self.status_selanjutnya_closing?.is_multi_user == "t" && self.status_submit == "terima") {
                    Swal.fire({
                        icon: "error",
                        title: "Pastikan " + self.status_selanjutnya_closing?.mspk_role_nama + " Terisi",
                    });
                    reject("stop");
                }
                resolve("next");
            });
            required_form.then(function (response) {
                if (response == "stop") {
                    return false;
                }

                axios
                    .post(
                        process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit-closing/ubah-status",
                        {
                            status_submit: self.status_submit,
                            catatan_verifikasi: self.catatan_verifikasi,
                            id: self.permitId,
                            evidence_raw: self.evidence_raw,
                            user_next_process: self.user_next_process,
                        },
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization: "Bearer " + localStorage.access_token,
                            },
                        }
                    )
                    .then(function (response) {
                        var response_data = response.data;
                        var response_data_fix = response_data.data;
                        console.log(response_data_fix);
                        if (response_data.meta.code == 200) {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Berhasil",
                                text: "Halaman segera diperbarui",
                                timer: 5000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    Swal.close();
                                    window.location.reload();
                                }
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data_fix.data.message,
                            });
                        }
                    })
                    .catch((e) => {
                        self.axiosCatchError = e.response;
                        console.log(e);
                    });
            });
        },
        exportPDFTimeline(id) {
            window.open(process.env.VUE_APP_BACKEND_URL_VERSION + "generate/work-permit-timeline?id=" + id, "_blank");

            // Swal.fire({
            //     title: '<i class="fas fa-spinner fa-spin"></i>',
            //     text: "Loading...",
            //     showConfirmButton: false,
            // });
            // let config = {
            //     method: "get",
            //     url: process.env.VUE_APP_BACKEND_URL_VERSION + "generate/work-permit-timeline",
            //     params: {
            //         id: id,
            //     },
            //     headers: {
            //         Accept: "application/json",
            //         Authorization: "Bearer " + localStorage.access_token,
            //     },
            //     responseType: "blob",
            // };
            // axios(config)
            //     .then(function (response) {
            //         Swal.close();
            //         var blob = new Blob([response.data], { type: "application/pdf" });
            //         var link = document.createElement("a");
            //         link.href = window.URL.createObjectURL(blob);
            //         link.download = "Work Permit Timeline.pdf";
            //         link.click();
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });
        },
        addUserNextProcess() {
            this.user_next_process.push({
                user: null,
            });
        },
        removeUserNextProcess(key_deleted) {
            this.user_next_process.splice(key_deleted, 1);
        },
        getApprove(timeline_use) {
            var approval_start = JSON.parse(timeline_use.approval_start);
            if (timeline_use.status_permit_kategori == "Pengajuan" || timeline_use.status_permit_kategori == "Draft") {
                approval_start = JSON.parse(timeline_use.approval_end);
            }
            // var approval_end = JSON.parse(timeline_use.approval_end);
            var htmls = "<b> Assigned By : </b>" + timeline_use.user.name;
            if (timeline_use?.timeline_kategori == "tolak") {
                htmls = "<b> Rejected By : </b>" + timeline_use.user.name;
            }
            if (timeline_use?.status_permit_kategori == "Proses" && approval_start.is_approval == "t" && timeline_use?.timeline_kategori == "terima") {
                htmls = "<b> Approved By : </b>" + timeline_use.user.name;
            }
            if (timeline_use?.status_permit_kategori == "Pengajuan" || approval_start.mspk_role_id == 42) {
                htmls = "<b> Submited By : </b>" + timeline_use.user.name;
            }
            if (timeline_use?.status_permit_kategori == "Draft") {
                htmls = "<b> Drafted By : </b>" + timeline_use.user.name;
            }

            return htmls;
        },
    },
};
</script>

<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">{{ title }}</h4>
                    <div class="page-title-right">
                        <b-button type="button" variant="success" class="btn btn-sm" @click="opentimeline()" style="margin-right: 10px"><i class="fas fa-code-branch"></i> Timeline</b-button>
                        <b-button type="button" variant="warning" class="btn btn-sm" @click="checkpdf(data_detail?.id)"><i class="fas fa-file"></i> Generate PDF</b-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <b-card border-variant="dark" header="Work Permit Information">
                    <b-card-body>
                        <div class="row" style="padding: 0px">
                            <div class="col-md-8" style="padding: 0px">
                                <table class="table table-sm table-striped table-borderles">
                                    <tbody>
                                        <tr>
                                            <th style="width: 250px">Work Permit Number</th>
                                            <th style="width: 10px">:</th>
                                            <td>{{ data_detail?.work_permit_no }}</td>
                                        </tr>
                                        <tr>
                                            <th style="width: 250px">Work Permit Date</th>
                                            <th style="width: 10px">:</th>
                                            <td>{{ data_detail?.work_permit_date }}</td>
                                        </tr>
                                        <tr>
                                            <th style="width: 250px">Initiator Name</th>
                                            <th style="width: 10px">:</th>
                                            <td>{{ data_detail?.user?.name }}</td>
                                        </tr>
                                        <tr>
                                            <th style="width: 250px">Work Permit Status</th>
                                            <th style="width: 10px">:</th>
                                            <td>{{ data_detail?.status_berjalan?.mspk_msp_nama }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-2" style="text-align: center; padding: 0px">
                                <img :src="qr_code" style="width: 100px" />
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
                <b-tabs content-class="text-muted">
                    <b-tab active class="border-0">
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">Work Permit Request</span>
                        </template>
                        <div v-if="work_type_data === 'General'">
                            <generalForm :data_detail="data_detail" :work_permit_type="work_type_selected" aksi="detail" />
                        </div>
                        <div v-else-if="work_type_data === 'Working at Height'">
                            <workingHeightForm />
                        </div>
                        <div v-else-if="work_type_data === 'Excavation'">
                            <excavationForm />
                        </div>
                        <div v-else-if="work_type_data === 'Confined Space'">
                            <confinedSpaceForm />
                        </div>
                        <div v-else-if="work_type_data === 'Electrical'">
                            <energizedForm />
                        </div>
                        <div v-else-if="work_type_data === 'Hot'">
                            <hotForm />
                        </div>
                        <b-card border-variant="dark" header="Dokumen Proses">
                            <b-card-body>
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <table class="table table-sm table-bordered mb-0">
                                            <thead class="bg-dark text-white text-center">
                                                <tr>
                                                    <th style="width: 20px">No</th>
                                                    <th>Nama Dokumen</th>
                                                    <th style="width: 150px">File</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="dokumen_proses.length == 0">
                                                    <td colspan="3">Data Belum Tersedia</td>
                                                </tr>
                                                <tr v-for="(dokumen_proses_val, dokumen_proses_key) in dokumen_proses" :key="dokumen_proses_key" v-else>
                                                    <td class="text-center">{{ dokumen_proses_key + 1 }}</td>
                                                    <td>{{ dokumen_proses_val.nama_file }}</td>
                                                    <td class="text-center">
                                                        <div class="btn btn-warning btn-sm" v-on:click="getFile(dokumen_proses_val.path)"><i class="fa fa-eye"></i> Lihat</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <template v-if="!is_tracking">
                                    <!-- jika bukan dari modul work permit traking-->
                                    <div class="row" v-if="hak_akses && status_sekarang?.mspk_status_kategori != 'Selesai' && status_sekarang?.mspk_status_type == 'Work Permit Request'">
                                        <div class="col-md-12 text-end" v-if="status_sekarang?.is_approval">
                                            <div class="btn btn-danger ms-1" @click="submitProcess('tolak')"><i class="bx bx-x"></i> Tolak</div>
                                            <div class="btn btn-primary ms-1" @click="submitProcess('terima')"><i class="bx bx-check"></i> Terima</div>
                                        </div>
                                        <div class="col-md-12 text-end" v-else>
                                            <div class="btn btn-primary ms-1" v-if="status_selanjutnya?.is_multi_user == 't'" @click="modal_verifikator = true"><i class="bx bx-save"></i> Submit</div>
                                            <div class="btn btn-primary ms-1" v-else @click="submitProcess('terima')"><i class="bx bx-save"></i> Submit</div>
                                        </div>
                                    </div>
                                </template>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                    <b-tab v-if="work_permit_closing?.id">
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="far fa-user"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">Work Permit Closing</span>
                        </template>
                        <b-card border-variant="dark" header="Work Permit Closing">
                            <b-card-body>
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <b-form-group label="Evidence File" label-for="formrow-nama-role-input">
                                            <table class="table table-sm table-bordered mb-0">
                                                <thead class="bg-dark text-white text-center">
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Nama File</th>
                                                        <th>File</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(row_dt, key_dt) in work_permit_closing_detail?.file_evidence" :key="key_dt">
                                                        <td>{{ key_dt + 1 }}</td>
                                                        <td>
                                                            {{ row_dt.nama_file }}
                                                        </td>
                                                        <td>
                                                            <div v-if="row_dt.url">
                                                                <span class="badge bg-success p-1" @click="getFile(row_dt.url)"><i class="fa fa-eye"></i> See Document </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </b-form-group>
                                        <b-form-group label="Closing statement" label-for="formrow-nama-role-input">
                                            {{ work_permit_closing?.closing_statement }}
                                        </b-form-group>
                                    </div>
                                </div>

                                <template v-if="!is_tracking">
                                    <!-- jika bukan dari modul work permit traking-->
                                    <div class="row" v-if="hak_akses_closing && status_sekarang_closing?.mspk_status_kategori != 'Selesai' && status_sekarang_closing?.mspk_status_type == 'Work Permit Closing'">
                                        <div class="col-md-12 text-end" v-if="status_sekarang_closing?.is_approval">
                                            <div class="btn btn-danger ms-1" @click="submitProcessClosing('tolak')"><i class="bx bx-x"></i> Tolak</div>
                                            <div class="btn btn-primary ms-1" @click="submitProcessClosing('terima')"><i class="bx bx-check"></i> Terima</div>
                                        </div>
                                        <div class="col-md-12 text-end" v-else>
                                            <div class="btn btn-primary ms-1" v-if="status_selanjutnya_closing?.is_multi_user == 't'" @click="modal_verifikator_closing = true"><i class="bx bx-save"></i> Submit</div>
                                            <div class="btn btn-primary ms-1" v-else @click="submitProcessClosing('terima')"><i class="bx bx-save"></i> Submit</div>
                                        </div>
                                    </div>
                                </template>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
        </div>

        <!-- Modal -->
        <b-modal v-model="modal_timeline" hide-footer centered header-class="border-0" title="Timeline Work Permit" size="lg">
            <div class="mb-4">
                <div class="row">
                    <div class="col-md-12 text-end">
                        <div class="btn btn-warning ms-1" @click="exportPDFTimeline(data_detail?.id)"><i class="fas fa-file-pdf"></i> Download Timeline</div>
                    </div>
                    <div class="col-md-12" style="text-align: center">
                        <ul class="verti-timeline list-unstyled">
                            <li class="event-list" v-for="(val, key) in timeline" :key="key">
                                <div class="event-timeline-dot">
                                    <i class="bx bx-right-arrow-circle font-size-18"></i>
                                </div>
                                <div class="d-flex">
                                    <div class="me-3">
                                        <h5 class="font-size-14">
                                            {{ fullDateTimeFormat(val.created_at) }}
                                            <i v-if="val.timeline_kategori == 'tolak'" class="bx bx-right-arrow-alt font-size-16 text-danger align-middle ms-2"></i>
                                            <i v-else class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                        </h5>
                                    </div>
                                    <div class="flex-grow-1 text-start">
                                        <div class="">{{ getNamaStatus(key) }}</div>

                                        <!-- <p v-if="val.timeline_kategori == 'tolak'"><b>Rejected By : </b>{{ val.user.name }}</p>
                                        <p v-else><b>Approved By : </b>{{ val.user.name }}</p> -->
                                        <p v-html="getApprove(val)"></p>
                                        <div v-if="val.remarks">
                                            <b>Remark : </b><br />
                                            <p>{{ val.remarks }}</p>
                                        </div>
                                        <div v-if="checkProsesMatrix(val)">
                                            <b>Asign To : </b><br />
                                            <ul>
                                                <li v-for="(val, key) in checkProsesMatrix(val)" :key="key">
                                                    {{ val }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <!-- <b-button variant="light" v-on:click="modal_qr = false"> <i class="bx bx-x"></i> Close </b-button> -->
            </div>
        </b-modal>
        <b-modal v-model="modal_evidence" hide-footer centered header-class="border-0" title="Evidence" size="lg">
            <div class="mb-4">
                <div class="row">
                    <div class="form-group">
                        <table class="table table-sm table-bordered mb-0">
                            <thead class="bg-dark text-white text-center">
                                <tr>
                                    <th>No</th>
                                    <th>Nama File</th>
                                    <th>File</th>
                                    <td>
                                        <div class="btn btn-success btn-sm" v-on:click="addEvidence()"><i class="bx bx-plus"></i></div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val, key) in evidence_raw" :key="key">
                                    <td>{{ key + 1 }}</td>
                                    <td>
                                        <input type="text" class="form-control" v-model="val.nama_file" />
                                    </td>
                                    <td>
                                        <input type="file" class="form-control mb-0 form-control-sm" :id="'files-doc-' + key" v-on:change="uploadEvidence(key)" accept=".pdf, .word, .excel, .png, .jpg" />
                                        <div class="respond-input-file float-left" :id="'duloading-' + key">
                                            <span v-if="val.path" class="badge bg-success p-1" @click="getFile(val.path)"><i class="fa fa-eye"></i> See Document </span>
                                        </div>
                                        <br />
                                        <small class="float-right">*) Max File 2 MB </small>
                                    </td>
                                    <td>
                                        <div class="btn btn-danger btn-sm" v-on:click="removeEvidence(key)"><i class="bx bx-minus"></i></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button variant="light" v-on:click="modalTolakSubmit = false"> <i class="bx bx-x"></i> Close </b-button>
                <b-button variant="primary" v-on:click="prosesVerifikasi()"> <i class="bx bx-save"></i> Submit </b-button>
            </div>
        </b-modal>
        <b-modal v-model="modalTolakSubmit" hide-footer centered header-class="border-0" title="Remark" size="lg">
            <div class="mb-4">
                <div class="row">
                    <div class="form-group">
                        <label for="">Remark <i class="text-danger">*</i></label>
                        <textarea class="form-control" v-model="catatan_verifikasi"></textarea>
                    </div>
                    <div class="form-group" v-if="status_submit == 'terima' && status_selanjutnya?.mspk_status_kategori != 'Selesai' && status_selanjutnya?.is_multi_user == 't'">
                        <label for="">Choose {{ status_selanjutnya?.mspk_role_nama }} <i class="text-danger">*</i></label>
                        <table class="table table-sm table-bordered mb-0">
                            <thead class="bg-dark text-white text-center">
                                <tr>
                                    <th>No</th>
                                    <th>Nama</th>
                                    <td>
                                        <div class="btn btn-success btn-sm" v-on:click="addUserNextProcess()"><i class="bx bx-plus"></i></div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, key) in user_next_process" :key="key">
                                    <td>{{ key + 1 }}</td>
                                    <td>
                                        <v-select :options="optionVerifikator" label="name" v-model="verifikatorSelected" placeholder="Choose"></v-select>
                                    </td>
                                    <td>
                                        <div class="btn btn-danger btn-sm" v-on:click="removeUserNextProcess(key)"><i class="bx bx-minus"></i></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button variant="light" v-on:click="modalTolakSubmit = false"> <i class="bx bx-x"></i> Close </b-button>
                <b-button variant="primary" v-on:click="prosesVerifikasi()"> <i class="bx bx-save"></i> Submit </b-button>
            </div>
        </b-modal>
        <b-modal v-model="modal_verifikator" hide-footer centered header-class="border-0" :title="'Choose ' + status_selanjutnya?.mspk_role_nama" size="lg">
            <div class="mb-4">
                <table class="table table-sm table-bordered mb-0">
                    <thead class="bg-dark text-white text-center">
                        <tr>
                            <th style="width: 15px">No</th>
                            <th>Nama</th>
                            <td style="width: 50px">
                                <div class="btn btn-success btn-sm" v-on:click="addUserNextProcess()"><i class="bx bx-plus"></i></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, key) in user_next_process" :key="key">
                            <td>{{ key + 1 }}</td>
                            <td>
                                <v-select :options="optionVerifikator" label="name" v-model="row.user" placeholder="Choose"></v-select>
                            </td>
                            <td>
                                <div class="btn btn-danger btn-sm" v-on:click="removeUserNextProcess(key)"><i class="bx bx-minus"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button variant="light" v-on:click="modal_verifikator = false"> <i class="bx bx-x"></i> Close </b-button>
                <b-button variant="primary" v-on:click="submitProcess('terima')"> <i class="bx bx-save"></i> Submit </b-button>
            </div>
        </b-modal>
        <b-modal v-model="modalTolakSubmitClosing" hide-footer centered header-class="border-0" title="Remark" size="lg">
            <div class="mb-4">
                <div class="row">
                    <div class="form-group" v-if="status_sekarang?.is_evidence">
                        <label for="">Evidence <i class="text-danger">*</i></label>
                        <table class="table table-sm table-bordered mb-0">
                            <thead class="bg-dark text-white text-center">
                                <tr>
                                    <th>No</th>
                                    <th>Nama File</th>
                                    <th>File</th>
                                    <td>
                                        <div class="btn btn-success btn-sm" v-on:click="addEvidence()"><i class="bx bx-plus"></i></div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val, key) in evidence_raw" :key="key">
                                    <td>{{ key + 1 }}</td>
                                    <td>
                                        <input type="text" class="form-control" v-model="val.nama_file" />
                                    </td>
                                    <td>
                                        <input type="file" class="form-control mb-0 form-control-sm" :id="'files-doc-' + key" v-on:change="uploadEvidence(key)" accept=".pdf, .word, .excel, .png, .jpg" />
                                        <div class="respond-input-file float-left" :id="'duloading-' + key"></div>
                                        <br />
                                        <small class="float-right">*) Max File 2 MB </small>
                                    </td>
                                    <td>
                                        <div class="btn btn-danger btn-sm" v-on:click="removeEvidence(key)"><i class="bx bx-minus"></i></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="form-group">
                        <label for="">Remark <i class="text-danger">*</i></label>
                        <textarea class="form-control" v-model="catatan_verifikasi"></textarea>
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button variant="light" v-on:click="modalTolakSubmitClosing = false"> <i class="bx bx-x"></i> Close </b-button>
                <b-button variant="primary" v-on:click="prosesVerifikasiClosing()"> <i class="bx bx-save"></i> Submit </b-button>
            </div>
        </b-modal>
        <b-modal v-model="modal_verifikator_closing" hide-footer centered header-class="border-0" :title="'Choose ' + status_selanjutnya_closing?.mspk_role_nama" size="lg">
            <div class="mb-4">
                <table class="table table-sm table-bordered mb-0">
                    <thead class="bg-dark text-white text-center">
                        <tr>
                            <th style="width: 15px">No</th>
                            <th>Nama</th>
                            <td style="width: 50px">
                                <div class="btn btn-success btn-sm" v-on:click="addUserNextProcess()"><i class="bx bx-plus"></i></div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, key) in user_next_process" :key="key">
                            <td>{{ key + 1 }}</td>
                            <td>
                                <v-select :options="optionVerifikator" label="name" v-model="row.user" placeholder="Choose"></v-select>
                            </td>
                            <td>
                                <div class="btn btn-danger btn-sm" v-on:click="removeUserNextProcess(key)"><i class="bx bx-minus"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-end mt-3 button-items">
                <b-button variant="light" v-on:click="modal_verifikator = false"> <i class="bx bx-x"></i> Close </b-button>
                <b-button variant="primary" v-on:click="submitProcessClosing('terima')"> <i class="bx bx-save"></i> Submit </b-button>
            </div>
        </b-modal>
    </Layout>
</template>
